#radio-cards-container {
    margin-top: 0.2rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.radio-card {
    border: 2px solid rgba(252, 249, 249, 0.1);
    border-radius: 10px;
    width: 85vw;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    transition: all 0.3s;    
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.radio-card:hover {
    border: 2px solid #016787;
    cursor: pointer;
}

.radio-card-check2 {
    border: 2px solid #016787;
    cursor: pointer;
    border-radius: 10px;
    width: 85vw;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.radio-card-check {
    display: none;
    position: absolute;
    top: 0.1rem;
    left: 0.5rem;
}

.radio-card-check i {
    font-size: 1.4rem;
    color: #016787;
}

.text-center {
    text-align: center;
}

.radio-card-icon img {
    width: 80px;
}

.radio-card-label {
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;
}

.radio-card-label-description {
    margin-top: 0.5rem;
    /*color: rgba(0, 0, 0, 0.7);*/
}

.radio-card.selected {
    border: 2px solid #016787;
}

.radio-card.selected .radio-card-check {
    display: inline-flex;
}

@keyframes blinker {
    50% { opacity: 0.0; }
  }